import React, { useRef } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import FormStyle from "./ContactForm.module.css";
import CloseIcon from "./CloseIcon";
import downarrow from "../../assets/wareeg/downArrow.svg";

const ContactForm = ({
  details,
  setDetails,
  handleInputChange,
  disabled,
  handleSubmit,
  disablebtn,
  selectOpen,
  setSelectOpen,
  isExperiencer,
  errMsgValid,
  type,
  setType,
  setDisablebtn,
}) => {
  const nameRef = useRef();
  const lastnameRef = useRef();
  const dobRef = useRef();
  const emailRef = useRef();
  const reasonRef = useRef();
  const messageRef = useRef();
  const zipcodeRef = useRef();
  const bookingidRef = useRef();
  return (
    <Form className={FormStyle.form} onSubmit={handleSubmit}>
      <Row>
        <Col lg={6}>
          <Form.Group className="position-relative">
            <Form.Label
              className={`${FormStyle.label} ${
                details.firstName && disabled
                  ? FormStyle.contactusblue
                  : disabled
                  ? FormStyle.contactuslabel
                  : FormStyle.contactusnotlabel
              }`}
            >
              First Name
            </Form.Label>
            <Form.Control
              ref={nameRef}
              className={`${FormStyle.input} `}
              type="text"
              name="firstName"
              placeholder="Eg Mark"
              onChange={handleInputChange}
            />
            {details.firstName !== "" && (
              <CloseIcon
                onClick={() => {
                  setDetails({ ...details, firstName: "" });
                  nameRef.current.value = "";
                }}
              />
            )}
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="position-relative">
            <Form.Label
              className={`${FormStyle.label} ${
                details.lastName && disabled
                  ? FormStyle.contactusblue
                  : disabled
                  ? FormStyle.contactuslabel
                  : FormStyle.contactusnotlabel
              }`}
            >
              Last Name
            </Form.Label>
            <Form.Control
              className={FormStyle.input}
              type="text"
              name="lastName"
              placeholder="Eg Brown"
              ref={lastnameRef}
              onChange={handleInputChange}
            />
            {details.lastName !== "" && (
              <CloseIcon
                onClick={() => {
                  setDetails({ ...details, lastName: "" });
                  lastnameRef.current.value = "";
                }}
              />
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="position-relative">
            <Form.Label
              className={`${FormStyle.label} ${
                errMsgValid.isEmail
                  ? FormStyle.contactusred
                  : details.email && disabled
                  ? FormStyle.contactusblue
                  : disabled
                  ? FormStyle.contactuslabel
                  : FormStyle.contactusnotlabel
              }`}
            >
              Email Address
            </Form.Label>
            <Form.Control
              ref={emailRef}
              className={FormStyle.input}
              type="email"
              name="email"
              placeholder="markbrown@example.com"
              onChange={handleInputChange}
            />
            {details.email !== "" && (
              <CloseIcon
                onClick={() => {
                  setDetails({ ...details, email: "" });
                  emailRef.current.value = "";
                }}
              />
            )}
            {errMsgValid.isEmail && (
              <div className={FormStyle.errorMsg}>Enter Valid Email</div>
            )}
          </Form.Group>
        </Col>
        <Col lg={6}>
          {!isMobile ? (
            <Form.Group className="input-group2 position-relative">
              <Form.Label
                className={`${FormStyle.label} ${
                  details.dob && disabled
                    ? FormStyle.contactusblue
                    : disabled
                    ? FormStyle.contactuslabel
                    : FormStyle.contactusnotlabel
                }`}
              >
                Date of Birth
              </Form.Label>
              <Form.Control
                type={type}
                autoComplete="off"
                disabled={disablebtn}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                value={details.dob}
                // placeholder="DD/MM/YY"
                name="dob"
                id="myDateInput"
                ref={dobRef}
                onChange={handleInputChange}
                className={`${
                  !details.dob
                    ? "contactinput dateplaceholder "
                    : "contactinput dateselectplaceholder"
                } ${FormStyle.input}`}
              />
              <span
                onClick={() => {
                  setDetails({ ...details, dob: "" });
                  dobRef.current.value = "";
                  setType("date");
                  setDisablebtn(false);
                }}
                className={FormStyle.crosslastname}
              >
                {details.dob !== "" ? (
                  <img src="images/icons/closeIcon.svg" alt="close icon" />
                ) : isMobile ? (
                  <img
                    src="images/icons/calendericon.png"
                    alt="calender icon"
                  />
                ) : (
                  ""
                )}
              </span>
            </Form.Group>
          ) : (
            <Form.Group className="input-group2 position-relative">
              <Form.Label
                className={`${FormStyle.label} ${
                  details.dob && disabled
                    ? FormStyle.contactusblue
                    : disabled
                    ? FormStyle.contactuslabel
                    : FormStyle.contactusnotlabel
                }`}
              >
                Date of Birth
              </Form.Label>
              <Form.Group
                type={type}
                autoComplete="off"
                value={details.dob}
                disabled={disablebtn}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                onInput={() => {
                  setType("text");
                }}
                // placeholder="DD/MM/YY"
                name="dob"
                id="myDateInput"
                ref={dobRef}
                onChange={handleInputChange}
                className={`${
                  !details.dob
                    ? "contactinput dateplaceholder "
                    : "contactinput dateselectplaceholder"
                } ${FormStyle.input}`}
              />
              <span
                onClick={() => {
                  setDetails({ ...details, dob: "" });
                  dobRef.current.value = "";
                  setType("date");
                  setDisablebtn(false);
                }}
                className={FormStyle.crosslastname}
              >
                {details.dob !== "" ? (
                  <img src="images/icons/closeIcon.svg" alt="close icon" />
                ) : isMobile ? (
                  <img
                    src="images/icons/calendericon.png"
                    alt="calender icon"
                  />
                ) : (
                  ""
                )}
              </span>
            </Form.Group>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="position-relative">
            <Form.Label
              className={`${FormStyle.label} ${
                errMsgValid.isBookingId
                  ? FormStyle.contactusred
                  : details.bookingId && disabled
                  ? FormStyle.contactusblue
                  : disabled
                  ? FormStyle.contactuslabel
                  : FormStyle.contactusnotlabel
              }`}
            >
              Experience Booking Id
            </Form.Label>
            <Form.Control
              name="bookingId"
              maxLength={9}
              onChange={handleInputChange}
              ref={bookingidRef}
              className={FormStyle.input}
              type="text"
              placeholder="XXXXX56895"
            />
            {details.bookingId !== "" && (
              <CloseIcon
                onClick={() => {
                  setDetails({ ...details, firstName: "" });
                  bookingidRef.current.value = "";
                }}
              />
            )}
            {errMsgValid.isBookingId && (
              <div className={FormStyle.errorMsg}>Enter Valid Booking Id</div>
            )}
          </Form.Group>
        </Col>
        <Col lg={6}>
          {isExperiencer ? (
            <div className="position-relative">
              <Form.Group className="input-group2 position-relative">
                <Form.Label
                  className={`${FormStyle.label} ${
                    details.reason && disabled
                      ? FormStyle.contactusblue
                      : disabled
                      ? FormStyle.contactuslabel
                      : FormStyle.contactusnotlabel
                  }`}
                >
                  Reason for Submission
                </Form.Label>
                <Form.Control
                  readOnly
                  name="reason"
                  placeholder="Select Reason"
                  value={details.reason}
                  ref={reasonRef}
                  onChange={handleInputChange}
                  autoComplete="off"
                  className={`${
                    !details.reason ? "contactselect" : "contactselected"
                  } ${FormStyle.select}`}
                  onClick={() => {
                    setSelectOpen((prev) => !prev);
                  }}
                />
                <span
                  onClick={() => {
                    setDetails({ ...details, reason: "" });
                    reasonRef.current.value = "";
                  }}
                  className={`${FormStyle.crosslastname}`}
                >
                  {details.reason !== "" ? (
                    <img
                      alt="arrow"
                      onClick={() => {
                        setSelectOpen(false);
                      }}
                      src="images/icons/closeIcon.svg"
                    />
                  ) : (
                    <img
                      src={downarrow}
                      alt="down arrow"
                      onClick={() => {
                        setSelectOpen((prev) => !prev);
                      }}
                    />
                  )}
                </span>
              </Form.Group>

              {selectOpen && (
                <div
                  className={FormStyle.selectMenu}
                  style={{ position: "absolute" }}
                >
                  <Card className={FormStyle.card}>
                    <div
                      className={FormStyle.dropdownitem1}
                      onClick={() => {
                        setDetails({ ...details, reason: "Complaint" });
                        setSelectOpen(false);
                      }}
                    >
                      Complaint
                    </div>
                    <div
                      className={FormStyle.dropdownitem1}
                      onClick={() => {
                        setDetails({ ...details, reason: "Refund" });
                        setSelectOpen(false);
                      }}
                    >
                      Refund
                    </div>
                    <div
                      className={FormStyle.dropdownitem1}
                      onClick={() => {
                        setDetails({
                          ...details,
                          reason: "Appeal Platform Suspension",
                        });
                        setSelectOpen(false);
                      }}
                    >
                      Appeal Platform Suspension
                    </div>
                    <div
                      className={FormStyle.dropdownitemlast}
                      onClick={() => {
                        setDetails({ ...details, reason: "Other" });
                        setSelectOpen(false);
                      }}
                    >
                      Other
                    </div>
                  </Card>
                </div>
              )}
            </div>
          ) : (
            <div className="position-relative">
              <Form.Group className="input-group2">
                <Form.Label
                  className={`${FormStyle.label} ${
                    details.reason && disabled
                      ? FormStyle.contactusblue
                      : disabled
                      ? FormStyle.contactuslabel
                      : FormStyle.contactusnotlabel
                  }`}
                >
                  Reason for Submission
                </Form.Label>
                <Form.Control
                  readOnly
                  name="reason"
                  placeholder="Select Reason"
                  value={details.reason}
                  ref={reasonRef}
                  onChange={handleInputChange}
                  autoComplete="off"
                  className={`${
                    !details.reason ? "contactselect" : "contactselected"
                  } ${FormStyle.input}`}
                  onClick={() => {
                    setSelectOpen((prev) => !prev);
                  }}
                />
                <span
                  onClick={() => {
                    setDetails({ ...details, reason: "" });
                    reasonRef.current.value = "";
                  }}
                  className={`${FormStyle.crosslastname}`}
                >
                  {details.reason !== "" ? (
                    <img
                      alt="arrow"
                      style={{ top: "30px" }}
                      onClick={() => {
                        setSelectOpen(false);
                      }}
                      src="images/icons/closeIcon.svg"
                    />
                  ) : (
                    <img
                      alt="down arrow"
                      src={downarrow}
                      onClick={() => {
                        setSelectOpen((prev) => !prev);
                      }}
                      style={{ top: "55px" }}
                    />
                  )}
                </span>
              </Form.Group>
              {selectOpen && (
                <div
                  className={FormStyle.selectMenu}
                  style={{ position: "absolute" }}
                >
                  <Card className="cardmenu">
                    <div
                      className={FormStyle.dropdownitem1}
                      onClick={() => {
                        setDetails({ ...details, reason: "Complaint" });
                        setSelectOpen(false);
                      }}
                    >
                      Complaint
                    </div>
                    <div
                      className={FormStyle.dropdownitem1}
                      onClick={() => {
                        setDetails({ ...details, reason: "Payout" });
                        setSelectOpen(false);
                      }}
                    >
                      Payout
                    </div>
                    <div
                      className={FormStyle.dropdownitem1}
                      onClick={() => {
                        setDetails({
                          ...details,
                          reason: "Appeal Platform Suspension",
                        });
                        setSelectOpen(false);
                      }}
                    >
                      Appeal Platform Suspension
                    </div>
                    <div
                      className={FormStyle.dropdownitem1}
                      onClick={() => {
                        setDetails({
                          ...details,
                          reason: "Appeal Rejected Application",
                        });
                        setSelectOpen(false);
                      }}
                    >
                      Appeal Rejected Application
                    </div>
                    <div
                      className={FormStyle.dropdownitemlast}
                      onClick={() => {
                        setDetails({ ...details, reason: "Other" });
                        setSelectOpen(false);
                      }}
                    >
                      Other
                    </div>
                  </Card>
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="position-relative">
            <Form.Label
              className={`${FormStyle.label} ${
                errMsgValid.isZipCode
                  ? FormStyle.contactusred
                  : details.zipCode && disabled
                  ? FormStyle.contactusblue
                  : disabled
                  ? FormStyle.contactuslabel
                  : FormStyle.contactusnotlabel
              }`}
            >
              Zip Code
            </Form.Label>
            <Form.Control
              className={FormStyle.input}
              type="text"
              placeholder="44946"
              name="zipCode"
              ref={zipcodeRef}
              maxLength={5}
              onChange={handleInputChange}
            />
            {details.zipCode !== "" && (
              <CloseIcon
                onClick={() => {
                  setDetails({ ...details, zipCode: "" });
                  zipcodeRef.current.value = "";
                }}
              />
            )}
            {errMsgValid.isZipCode && (
              <div className={FormStyle.errorMsg}>*InValid ZipCode</div>
            )}
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="position-relative">
            <Form.Label
              className={`${FormStyle.label} ${
                details.message && disabled
                  ? FormStyle.contactusblue
                  : disabled
                  ? FormStyle.contactuslabel
                  : FormStyle.contactusnotlabel
              }`}
            >
              Message
            </Form.Label>
            <Form.Control
              ref={messageRef}
              className={FormStyle.input}
              type="text"
              name="message"
              onChange={handleInputChange}
              placeholder="Write Something..."
            />
            {details.message !== "" && (
              <CloseIcon
                onClick={() => {
                  setDetails({ ...details, message: "" });
                  messageRef.current.value = "";
                }}
              />
            )}
          </Form.Group>
        </Col>
      </Row>
      <Col>
        <Button
          className={`${FormStyle.button} ${
            disabled ? FormStyle.disablebtn : FormStyle.enablebtn
          }`}
          type="submit"
        >
          Submit
        </Button>
      </Col>
    </Form>
  );
};

export default ContactForm;

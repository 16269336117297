import React from "react";
import SupportPageLayout from "../Components/SupportPageLayout";
import SupportPageTitle from "../Components/SupportPageTitle";

const Cancellation = () => {
  return (
    <SupportPageLayout>
      <SupportPageTitle text="Cancellation Policy" />
      <div>
        <h4 className="fs-5 mb-3">For Experiencers:</h4>
        <p>
          At Wareeg, we understand that unexpected circumstances can arise, and
          sometimes cancellations are necessary. To ensure a fair and consistent
          policy for all parties, we have established the following guidelines:
        </p>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Cancellation:</h4>
        <ul className="fs-5">
          <li>
            <p><span className="fw-semibold">Pending reservation: </span>If the local has not accepted or declined the experience requested by the experiencer, the payment charges will be refunded and the funds will be returned.</p>
          </li>
          <li>
            <p><span className="fw-semibold">Confirmed reservation or no-show: </span>If a confirmed reservation is cancelled 24 hours before the start time, the Experiencer will receive a refund minus fees. However, there will be no refund for cancellations within 24 hours or no-shows</p>
          </li>
          <li>
            <p><span className="fw-semibold">Local cancels or no-show: </span>Experiencer gets full refund minus fees.</p>
          </li>
        </ul>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Booking Requests:</h4>
        <p>To ensure timely processing, the local should either accept or decline the request within 48 hours or for same day bookings, before experience start time, otherwise it will expire. If declined or expired, there will be no charge to the Experiencer and the local will not receive payouts.</p>
        <h4 className="fs-5 mb-3">For Locals:</h4>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Payout: </h4>
        <ul>
          <li>
            <p><span className="fw-semibold">Pending booking: </span>Local didn't accept or decline yet, no payout for them.</p>
          </li>
          <li>
            <p><span className="fw-semibold">Confirmed booking or no-show: </span>Experiencer gets full refund if cancelled 24 hours before start time, no refund for cancellations within 24 hours or no-shows. Local gets full payout if cancelled within 24 hours or no-show.</p>
          </li>
          <li>
            <p><span className="fw-semibold">Local cancels or no-show: </span>Experiencer gets full refund, local gets no payout.</p>
          </li>
        </ul>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Booking Requests: </h4>
        <p>Local must accept or decline within 48 hours, else it expires. If declined or expired, Experiencer won't be charged and local won't receive payouts.</p>
        <p>If either party is prevented from performing its obligations under the Terms and Conditions due to circumstances beyond its reasonable control, such as acts of God, natural disasters, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemics, epidemics or disease, strikes, shortages of transportation facilities, fuel, energy, labor, or materials, Wareeg shall not be liable for any resulting delay or failure. In such a situation, the affected party will promptly inform the other party and use reasonable commercial efforts to resume performance as soon as it is reasonably practicable.</p>
        <p>Wareeg reserves the right to make changes to this Cancellation Policy at any time and for any reason, with or without notice. By using Wareeg you agree to this cancellation policy and any changes made in the future.</p>
      </div>
    </SupportPageLayout>
  );
};

export default Cancellation;

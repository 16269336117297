import React from 'react'
import SupportPageLayout from '../Components/SupportPageLayout'
import SupportPageTitle from '../Components/SupportPageTitle'
import { Link } from 'react-router-dom'

const Privacy = () => {
  return (
    <SupportPageLayout>
      <SupportPageTitle text="Privacy and Policy" />
      <div>
        <p>This Privacy Policy ("Policy") outlines how Wareeg ("we", "us", or "our") collects, uses, and protects the personal information of users ("Users") of our peer-to-peer marketplace platform ("Platform") that facilitates experiencers ("Guests") to book locals ("Hosts") to host a private experience anytime, anywhere. This Policy is in compliance with the General Data Protection Regulation ("GDPR") and the California Consumer Privacy Act ("CCPA").</p>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Information We Collect</h4>
        <p>We collect<span style={{ color: 'red' }}>*</span> personal information when Users register an account or use the Platform, including:</p>
        <ul>
          <li><p>Contact information: name, email address, phone number, and location</p></li>
          <li><p>Personal information: SSN, ID, DOB, residential address, and availability</p></li>
          <li><p>Payment information: payment card details or direct deposit information</p></li>
          <li><p>Profile information: profile picture, interests, languages, hosting city, and bio</p></li>
          <li><p>Communication information: any correspondence Users have with each other through the Platform</p></li>
        </ul>
        <p><span style={{ color: 'red' }}>*</span>The information you will be asked to input during registration may vary depending on whether you are registering for a guest or host account/profile.</p>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>How We Use Your Information</h4>
        <p>We use personal information for the following purposes:</p>
        <ul>
          <li><p>To provide and improve our Platform</p></li>
          <li><p>To verify User identity and prevent fraudulent activity</p></li>
          <li><p>To communicate with Users about their experiences and bookings</p></li>
          <li><p>To process payments, payouts and refunds</p></li>
          <li><p>To personalize User experience and match Users with compatible hosts or guests</p></li>
          <li><p>To comply with legal obligations, including GDPR and CCPA</p></li>
        </ul>
        <p>We will not use your personal information for marketing purposes without your consent.</p>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Information Sharing</h4>
        <p>We may share personal information with the following third-party service providers:</p>
        <ul>
          <li><p>Payment processing companies to process payments and payouts</p></li>
          <li><p>Hosting providers to host the Platform</p></li>
          <li><p>Customer service providers to assist with user support</p></li>
          <li><p>Communication providers to enable communication between Users</p></li>
        </ul>
        <p>We may also share personal information with law enforcement or government agencies in response to a valid legal request.</p>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Cookies</h4>
        <p>We use cookies and similar technologies to enhance User experience on our Platform. Cookies are small data files that are placed on your device. They may be used to store your preferences or track your activity on our Platform. You may opt-out of cookies by adjusting your browser settings.</p>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Data Retention</h4>
        <p>We retain personal information for as long as necessary to provide our Platform and fulfill our legal obligations. We will securely delete personal information when it is no longer needed.</p>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Security</h4>
        <p>We take appropriate measures to protect personal information against unauthorized access, alteration, or destruction. We restrict access to personal information to employees and contractors who need to know the information to provide our services.</p>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Your Rights</h4>
        <p>You have the right to access, correct, or delete your personal information. You may also request that we restrict the processing of your personal information or transfer it to another party. To exercise these rights, please contact us using the contact information below.</p>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Children's Privacy</h4>
        <p>Our Platform is not intended for use by children under the age of 18. We do not knowingly collect personal information from children under 18. If you become aware that a child under 18 has provided us with personal information, please contact us using the contact information below.</p>
        <h4 className="fs-5 mb-3" style={{ color: '#4189dd' }}>Changes to this Policy</h4>
        <p>We may update this Policy from time to time to reflect changes in our privacy practices. We will notify Users of any material changes to this Policy by email or through our Platform.</p>
        <p><Link to="/" style={{ textDecoration: 'none', color: '#6B6B6B' }}>Contact Us If you have any questions or concerns about this Policy, please contact us at </Link><a href="https://www.wareeg.com/" target="_blank">wareeg.com.</a></p>
      </div>
    </SupportPageLayout>
  )
}

export default Privacy
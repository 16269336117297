import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import ToggleStyle from "./ToggleSwitch.module.css";

const ToggleSwitch = (props) => {
  const toggleRef = useRef();
  const [isExperiencer, setIsExperiencer] = useState(props.isExperiencer);
  useEffect(() => {
    !props.isExperiencer &&
      toggleRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div ref={toggleRef}>
      <div className={""}>
        <div className={ToggleStyle.buttons}>
          <Button
            // className={`${ToggleStyle.button} + active`}
            className={`${ToggleStyle.button} ${
              isExperiencer && ToggleStyle.active
            }`}
            onClick={() => {
              props.setIsExperiencer(true);
              setIsExperiencer(true);
            }}
          >
            Experiencer
          </Button>
          <Button
            className={`${ToggleStyle.button} ${
              !isExperiencer && ToggleStyle.active
            }`}
            onClick={() => {
              props.setIsExperiencer(false);
              setIsExperiencer(false);
            }}
          >
            Local
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;

import React from "react";
import { Col } from "react-bootstrap";

const ExperiencerHowItWork = () => {
  return (
    <>
      <Col sm="12" lg="6" xl="6" xxl="5">
        <ul className="vstack gap-4 p-0 list-unstyled">
          <li className="d-flex gap-3">
            <div>
              <svg
                width="35"
                height="34"
                viewBox="0 0 35 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5026 14.1693C20.6322 14.1693 23.1693 11.6322 23.1693 8.5026C23.1693 5.37299 20.6322 2.83594 17.5026 2.83594C14.373 2.83594 11.8359 5.37299 11.8359 8.5026C11.8359 11.6322 14.373 14.1693 17.5026 14.1693Z"
                  fill="#42B5D5"
                />
                <path
                  d="M28.8307 24.7891C28.8307 28.3095 28.8307 31.1641 17.4974 31.1641C6.16406 31.1641 6.16406 28.3095 6.16406 24.7891C6.16406 21.2686 11.2386 18.4141 17.4974 18.4141C23.7562 18.4141 28.8307 21.2686 28.8307 24.7891Z"
                  fill="#42B5D5"
                />
              </svg>
            </div>
            <div>
              <h6 className="pt-2 fs-5 fw-semibold">Create Profile</h6>
              <p className="mb-0">
                Start your journey by creating a profile to unlock and
                experience any destination.
              </p>
            </div>
          </li>
          <li className="d-flex gap-3">
            <div>
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5026 3.15625C25.3226 3.15625 31.6693 9.50292 31.6693 17.3229C31.6693 25.1571 25.3226 31.4896 17.5026 31.4896C9.66844 31.4896 3.33594 25.1571 3.33594 17.3229C3.33594 9.50292 9.66844 3.15625 17.5026 3.15625ZM22.9568 12.6621C23.1126 12.1663 22.6593 11.6988 22.1634 11.8546L14.9101 14.1213C14.6126 14.2204 14.3718 14.4471 14.2868 14.7446L12.0201 22.0121C11.8643 22.4938 12.3318 22.9613 12.8134 22.8054L20.0384 20.5388C20.3359 20.4538 20.5768 20.2129 20.6618 19.9154L22.9568 12.6621Z"
                  fill="#10A667"
                />
              </svg>
            </div>
            <div>
              <h6 className="pt-2 fs-5 fw-semibold">Add Interests</h6>
              <p className="mb-0">
                Select 10 interests from over 200+ options to personalize your
                adventures.
              </p>
            </div>
          </li>
          <li className="d-flex gap-3">
            <div>
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_4985_47569)">
                  <path
                    d="M31.4 18.4778C31.502 18.5352 31.5224 18.6569 31.5265 18.7032L31.5275 18.7217L31.4141 26.0381C31.4141 28.9566 29.1528 31.3366 26.3054 31.4825L26.0281 31.4896H8.96292C6.07944 31.4896 3.72804 29.2008 3.58389 26.3188L3.57689 26.0381L3.47768 18.7217C3.47768 18.7217 3.47768 18.5496 3.60524 18.4778C3.71863 18.4061 3.86037 18.4922 3.87454 18.5065C6.73764 20.4289 10.1819 21.7057 13.7962 22.2365C14.3064 22.3082 14.8167 21.9782 14.9584 21.4618C15.2508 20.3589 16.1535 19.6091 17.2579 19.5197L17.4813 19.5107H17.5239C18.7286 19.5107 19.735 20.2854 20.0468 21.4618C20.1885 21.9782 20.6988 22.3082 21.209 22.2365C24.8233 21.7057 28.2676 20.4289 31.1307 18.5065C31.1307 18.5065 31.159 18.4922 31.1874 18.4778C31.2441 18.4491 31.3291 18.4348 31.4 18.4778ZM17.4955 21.5622C16.9628 21.5622 16.5135 21.9721 16.4423 22.4934L16.4325 22.6382V24.4888C16.4325 25.0913 16.9144 25.5647 17.4955 25.5647C18.0412 25.5647 18.4797 25.1669 18.549 24.6365L18.5585 24.4888V22.6382C18.5585 22.05 18.0908 21.5622 17.4955 21.5622ZM19.3239 3.15625C21.45 3.15625 23.1934 4.77734 23.4627 6.8575H26.2691C29.2456 6.8575 31.6693 9.31065 31.6693 12.3233C31.6693 12.3233 31.5842 13.5986 31.5559 15.3747C31.553 15.5153 31.485 15.653 31.373 15.7362C30.6913 16.2397 30.0676 16.6558 30.0109 16.6845C27.6581 18.2625 24.924 19.3729 22.0113 19.9252C21.8213 19.9625 21.6343 19.8635 21.5379 19.6942C20.7215 18.2797 19.1964 17.3587 17.4955 17.3587C15.806 17.3587 14.2667 18.2697 13.4262 19.6856C13.3284 19.852 13.1442 19.9481 12.9557 19.9123C10.0671 19.3585 7.33294 18.2496 4.99427 16.6988L3.63359 15.752C3.5202 15.6802 3.44933 15.5511 3.44933 15.4077C3.40681 14.676 3.33594 12.3233 3.33594 12.3233C3.33594 9.31065 5.75965 6.8575 8.73614 6.8575H11.5284C11.7977 4.77734 13.541 3.15625 15.6671 3.15625H19.3239ZM19.3239 5.30814H15.6671C14.7175 5.30814 13.9096 5.96805 13.6828 6.8575H21.3083C21.0815 5.96805 20.2736 5.30814 19.3239 5.30814Z"
                    fill="#F99746"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4985_47569">
                    <rect
                      width="34"
                      height="34"
                      fill="white"
                      transform="translate(0.5 0.320312)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>
              <h6 className="pt-2 fs-5 fw-semibold">Book</h6>
              <p className="mb-0">
                Book local hosts who share your interests, in any destination,
                at any time, to experience the place through their eyes and
                passions.
              </p>
            </div>
          </li>
        </ul>
      </Col>
    </>
  );
};

export default ExperiencerHowItWork;

import React from 'react';
import { BsTwitterX, BsInstagram, BsLinkedin } from "react-icons/bs";


const SocialIcons = ({ color, align }) => {
  return (
    <div className={`hstack gap-3 gap-xl-4 ${align ? 'justify-content-center' : undefined}`}>
      <a href="https://x.com/wareeghq?s=21&t=ESfwnsaiTQH-rgUiT_CFWA" target="_blank">
        <BsTwitterX style={{ color }} />
      </a>
      <a href="https://www.instagram.com/wareeghq?igsh=MWNldm1ocjZ6bmN6bg%3D%3D&utm_source=qr" target="_blank">
        <BsInstagram style={{ color }} />
      </a>
      <a href="https://www.linkedin.com/company/wareeg?trk=blended-typeahead" target="_blank">
        <BsLinkedin style={{ color }} />
      </a>
    </div>
  )
}

export default SocialIcons
import React, { useEffect, useState } from "react";
import CommonLayout from "../CommonLayout";
import SectionTitle from "../SectionTitle";
import { Col, Form, Row } from "react-bootstrap";
import styles from "./CostSaving.module.css";
import AmountDisplay from "./AmountDisplay";

let hoursList = [];
for (let i = 1; i <= 24; i++) {
  hoursList.push(i);
}
let peopleList = [];
for (let i = 1; i <= 20; i++) {
  peopleList.push(i);
}

const CostSaving = () => {
  const [hours, setHours] = useState(1);
  const [people, setPeople] = useState(1);
  let [wareegAmount, setWareegAmount] = useState(26.67);
  let [otherAmount, setOtherAmount] = useState(29.67);

  useEffect(() => {
    let amountForOne = 26.67 * Number(hours);
    let amountForMorePeople =
      26.67 * Number(hours) + (Number(people) - 1) * 18.75;
    setOtherAmount((29.67 * Number(hours) * Number(people)).toFixed(2));
    if (people === 1) setWareegAmount(amountForOne.toFixed(2));
    else setWareegAmount(amountForMorePeople.toFixed(2));
  }, [people, hours]);
  return (
    <section className="py-5">
      <CommonLayout>
        <SectionTitle text="Experience Cost Savings: Wareeg vs. Other Services" />
        <div className="mt-3 mb-3 mb-xl-5">
          <Row className="justify-content-center">
            <Col sm="12" lg="8" xl="10">
              <div className="d-md-flex text-center align-items-center justify-content-center gap-2">
                <span className="fs-1 fw-semibold" style={{ color: "#103662" }}>
                  I want a
                </span>
                <select
                  className={styles.custom_select}
                  style={{ color: '#1EB0D9' }}
                  onChange={(e) => {
                    setHours(e.target.value);
                  }}
                >
                  {hoursList.map((value, index) => {
                    return (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    );
                  })}
                </select>
                <span className="fs-1 fw-semibold" style={{ color: "#103662" }}>
                  hour experience
                </span>
              </div>
              <div className="d-md-flex text-center align-items-center justify-content-center gap-2">
                <span className="fs-1 fw-semibold" style={{ color: "#103662" }}>
                  with a party size of
                </span>
                <select
                  className={styles.custom_select}
                  style={{ color: '#0DDFBF' }}
                  onChange={(e) => {
                    setPeople(e.target.value);
                  }}
                >
                  {peopleList.map((value, index) => (
                    <option value={value} key={index}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mb-3 mb-xl-5">
          <Row className="justify-content-center">
            <Col sm="12" xl="10">
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex flex-wrap flex-column justify-content-center flex-md-row gap-3">
                  <div className="d-flex flex-column flex-sm-row align-items-center gap-2">
                    <div className="d-flex flex-column text-center text-sm-end">
                      <small>The cost to experience</small>
                      <span className="fw-semibold">with Wareeg</span>
                    </div>
                    <div
                      className={`${styles.btn} ${styles.btn_success} fs-4 fw-semibold`}
                    >
                      <AmountDisplay amount={wareegAmount} />
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-sm-row align-items-center gap-2">
                    <div
                      className={`${styles.btn} ${styles.btn_danger} fs-4 fw-semibold`}
                    >
                      <AmountDisplay amount={otherAmount} />
                    </div>
                    <div className="d-flex flex-column text-center text-sm-start">
                      <small>The cost to experience</small>
                      <span className="fw-semibold">
                        with any other service
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="justify-content-center mb-3 mb-xl-5">
          <Col sm="12" xl="6">
            <p className="text-center fw-normal">
              <small className={styles.disclaimer_text}>
                Disclaimer: Our cost comparison does not include taxes and fees,
                and is based on publicly available information which may vary.
              </small>
            </p>
          </Col>
        </Row>
      </CommonLayout>
    </section>
  );
};

export default CostSaving;

import React from 'react'
import { ScrollingNumber } from 'react-scrolling-currency';

const AmountDisplay = ({ amount }) => {
  console.log(amount)
  return (
    <ScrollingNumber number={amount} isCurrency={true} locale="en-US" currencyCode="USD" />
  )
}

export default AmountDisplay
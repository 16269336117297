import React from "react";
import CommonLayout from "../CommonLayout";
import SectionTitle from "../SectionTitle";
import { Col, Row } from "react-bootstrap";
import styles from "./AboutUs.module.css";
import aboutimg from "../../assets/about-img.png";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <section className="py-5 bg-white">
      <CommonLayout>
        <SectionTitle text="ABOUT US" />
        <div className="mb-3 mb-xl-5">
          <h3 className={`${styles.main_title} fs-1 fw-semibold mt-3 mt-xl-4`}>
            Be the First to <span>Experience</span> Wareeg!
          </h3>
        </div>
        <Row className="align-items-center">
          <Col sm="12" lg="5" xl="6" className="mb-4 mb-lg-0">
            <div className="px-3 px-xl-5">
              <img src={aboutimg} className="img-fluid" alt="App screens" />
            </div>
          </Col>
          <Col sm="12" lg="7" xl="6">
            <h6 className={`${styles.text_primary} fs-5 mb-3 mb-lg-4`}>Wareeg: </h6>
            <ul className="p-0 list-unstyled mb-3 mb-lg-4">
              <li className="mb-2">
                <div>
                  <span className="fw-semibold">
                    To Go Around or Experience:{" "}
                  </span>
                  Refers to the act of exploring or experiencing a destination
                  or location.
                </div>
                <div>
                  <span className="fw-semibold"> Usage: </span>I'm just
                  wareeg-ing around the city.
                </div>
              </li>
              <li>
                <div>
                  <span className="fw-semibold">To Turn Around: </span>{" "}
                  Indicates the action of reversing direction, especially when
                  missing a turn.
                </div>
                <div>
                  <span className="fw-semibold">Usage: </span> You missed the
                  turn, wareeg and go back.
                </div>
              </li>
            </ul>
            <Row className="mb-4">
              <Col sm="12" lg="6" className="mb-3 mb-lg-0">
                <div className={styles.card}>
                  <div
                    className={`${styles.text_primary} fs-6 fw-semibold mb-2`}
                  >
                    Experience Anywhere, Anything, Anytime Through Locals.
                  </div>
                  <div className={styles.text_secondary}>
                    <span className="fw-semibold">Experiencer: </span>Someone
                    who seeks unique experiences by connecting with locals based
                    on shared interests.
                  </div>
                </div>
              </Col>
              <Col sm="12" lg="6">
                <div className={styles.card}>
                  <div
                    className={`${styles.text_primary} fs-6 fw-semibold mb-2`}
                  >
                    Earn Income by Hosting Activities You Already Enjoy and Do.
                  </div>
                  <div className={styles.text_secondary}>
                    <span className="fw-semibold">Local: </span> A resident who
                    hosts activities and shares their city and interests with
                    experiencers.
                  </div>
                </div>
              </Col>
            </Row>
            <div>
              <Link to="/signup" className={styles.btn_primary}>Get Early Access</Link>
            </div>
          </Col>
        </Row>
      </CommonLayout>
    </section>
  );
};

export default AboutUs;

import React from 'react';
import Slider from "./Slider";
import {sliderLocal } from "../../utils";

const LocalSlider = () => {
  return (
    <Slider items={sliderLocal} />
  )
}

export default LocalSlider
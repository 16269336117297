import React from "react";
import Slider from "./Slider";
import { sliderExperience } from "../../utils";

const ExperiencerSlider = () => {
  return (
    <Slider items={sliderExperience} />
  );
};

export default ExperiencerSlider;

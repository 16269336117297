import React, { useState, useRef, useEffect } from "react";
import CommonLayout from "../CommonLayout";
import SectionTitle from "../SectionTitle";
import { Col, Row } from "react-bootstrap";
import styles from "./HowItWorks.module.css";
import ExperiencerHowItWork from "./ExperiencerHowItWork";
import LocalHowItWork from "./LocalHowItWork";
import mobileFrame from '../../assets/mobile.svg';
import ExperiencerSlider from "./ExperiencerSlider";
import LocalSlider from "./LocalSlider";

const HowItWorks = ({ fromHowItWorks, local }) => {
  const sectionRef = useRef()
  const [isExperiencer, setIsExperiencer] = useState(true);

  useEffect(() => {
    fromHowItWorks && sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }, [fromHowItWorks])

  useEffect(() => {
    setIsExperiencer(false)
    local && sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }, [local])

  return (
    <section className="py-5 bg-white" ref={sectionRef}>
      <CommonLayout>
        <SectionTitle text="HOW IT WORKS" />
        <div className="mt-3 mt-xl-4 mb-4 mb-xl-5 py-3 py-lg-4">
          <div className={styles.toggle_switch_bg}>
            <div className={`${styles.toggle_switch} ${isExperiencer ? styles.toggle_switch_active : undefined}`} onClick={() => { setIsExperiencer(true) }}>Experiencer</div>
            <div className={`${styles.toggle_switch} ${!isExperiencer ? styles.toggle_switch_active : undefined}`} onClick={() => { setIsExperiencer(false) }}>Local</div>
          </div>
        </div>
        <Row className="justify-content-center align-items-center">
          <Col sm="12" lg="6" xl="5" xxl="5" className="mb-4 mb-lg-0">
            <div className={styles.slider_bg} style={{ backgroundImage: `url('${mobileFrame}')` }}>
              {isExperiencer ? <ExperiencerSlider /> : <LocalSlider />}
            </div>
          </Col>
          {isExperiencer ? <ExperiencerHowItWork /> : <LocalHowItWork />}
        </Row>
      </CommonLayout>
    </section>
  );
};

export default HowItWorks;

import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import successIcon from "../assets/Animation.gif";
import qrAndroid from "../assets/qrcode/iphone1.png";
import qrIphone from "../assets/qrcode/android1.png";
import SocialIcons from "./SocialIcons";
import CloseIcon from "../Components/ContactUs/CloseIcon";
import styles from "./Thankyou.module.css";
import googlePlayBtn from "../assets/qrcode/googleplaybtn.svg";
import appStoreBtn from "../assets/qrcode/appstorebtn.svg";
// import { isMobile } from "react-device-detect";

const Thankyou = ({ show, modalHandler }) => {

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className={styles.close} onClick={() => modalHandler(false)}>
          <CloseIcon />
        </div>
        <Modal.Body className="text-center rounded-4">
          <Row className="justify-content-center mb-3">
            <Col sm="12" md="10">
              <div className="text-center">
                <img
                  src={successIcon}
                  alt="Success Iocn"
                  style={{ width: "100px" }}
                />
              </div>
              <h4 className="fs-4 fw-semibold">
                Thank you for signing up! Stay tuned for more updates.
              </h4>
              <p className="px-lg-5">
                Get Early Access! Download the Wareeg App Now!"
              </p>
              <div className="hstack d-block d-md-none gap-3 justify-content-center mb-3">
                <a href="https://play.google.com/store/apps/details?id=com.wareeg&pcampaignid=web_share" target="_blank">
                  <img src={googlePlayBtn} alt="Google Play" />
                </a>
                <a href="https://apps.apple.com/us/app/wareeg/id6444090450" target="_blank">
                  <img src={appStoreBtn} alt="App Store" />
                </a>
              </div>
              <div className="hstack d-none d-md-flex gap-3 mb-3">
                <div className="card rounded-4">
                  <div className="card-body">
                    <img
                      src={qrAndroid}
                      alt="QR Code Android"
                      className="img-fluid"
                    />
                    <img className="mt-1" src={googlePlayBtn} alt="Google Play" />
                  </div>
                </div>
                <div className="card rounded-2">
                  <div className="card-body">
                    <img
                      src={qrIphone}
                      alt="QR Code Iphone"
                      className="img-fluid"
                    />
                    <img className="mt-1" src={appStoreBtn} alt="App Store" />
                  </div>
                </div>
              </div>
              <p>
                Stay Connected! Follow us on social media for updates and
                exclusive content.
              </p>
            </Col>
          </Row>
          <div className="mb-3">
            <SocialIcons color="rgba(6, 26, 50, 0.6)" align={true} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Thankyou;

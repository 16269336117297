import React from "react";
import SupportPageTitle from "../Components/SupportPageTitle";
import SupportPageLayout from "../Components/SupportPageLayout";
import CollapseFaq from "../Components/CollapseFaq";
import { Link } from "react-router-dom";
import styles from '../pages/CommonList.module.css';

const Legal = () => {
  return (
    <SupportPageLayout>
      <SupportPageTitle text="Legal Terms" />
      <CollapseFaq title="Cancellation Policy">
        <div className="p-3">
          <h4 className="fs-5 mb-3">For Experiencers:</h4>
          <p>
            At Wareeg, we understand that unexpected circumstances can arise,
            and sometimes cancellations are necessary. To ensure a fair and
            consistent policy for all parties, we have established the following
            guidelines:
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Cancellation:
          </h4>
          <ul>
            <li>
              <p>
                <span className="fw-semibold">Pending reservation: </span>If the
                local has not accepted or declined the experience requested by
                the experiencer, the payment charges will be refunded and the
                funds will be returned.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold">
                  Confirmed reservation or no-show:{" "}
                </span>{" "}
                If a confirmed reservation is cancelled 24 hours before the start
                time, the Experiencer will receive a refund minus fees. However,
                there will be no refund for cancellations within 24 hours or
                no-shows.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold">Local cancels or no-show: </span>
                Experiencer gets full refund minus fees.
              </p>
            </li>
          </ul>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Booking Requests:
          </h4>
          <p>
            To ensure timely processing, the local should either accept or
            decline the request within 48 hours or for same day bookings, before
            experience start time, otherwise it will expire. If declined or
            expired, there will be no charge to the Experiencer and the local
            will not receive payouts.
          </p>
          <h4 className="fs-5 mb-3">For Locals:</h4>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Payout:
          </h4>
          <ul>
            <li>
              <p>
                <span className="fw-semibold">Pending booking: </span>Local
                didn't accept or decline yet, no payout for them.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold">
                  Confirmed booking or no-show:{" "}
                </span>
                Experiencer gets full refund if cancelled 24 hours before start
                time, no refund for cancellations within 24 hours or no-shows.
                Local gets full payout if cancelled within 24 hours or no-show.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold">Local cancels or no-show: </span>
                Experiencer gets full refund, local gets no payout.
              </p>
            </li>
          </ul>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Booking Requests:
          </h4>
          <p>
            Local must accept or decline within 48 hours, else it expires. If
            declined or expired, Experiencer won't be charged and local won't
            receive payouts.
          </p>
          <p>
            If either party is prevented from performing its obligations under
            the Terms and Conditions due to circumstances beyond its reasonable
            control, such as acts of God, natural disasters, war, terrorism,
            riots, embargoes, acts of civil or military authorities, fire,
            floods, accidents, pandemics, epidemics or disease, strikes,
            shortages of transportation facilities, fuel, energy, labor, or
            materials, Wareeg shall not be liable for any resulting delay or
            failure. In such a situation, the affected party will promptly
            inform the other party and use reasonable commercial efforts to
            resume performance as soon as it is reasonably practicable.
          </p>
          <p>
            Wareeg reserves the right to make changes to this Cancellation
            Policy at any time and for any reason, with or without notice. By
            using Wareeg you agree to this cancellation policy and any changes
            made in the future.
          </p>
        </div>
      </CollapseFaq>
      <CollapseFaq title="Cancellation Policy for Locals">
        <div className="p-3">
          <h4 className="fs-5 mb-3">For Locals:</h4>
          <p>
            At Wareeg, we understand that unexpected circumstances can arise,
            and sometimes cancellations are necessary. To ensure a fair and
            consistent policy for all parties, we have established the following
            guidelines:
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Payout:
          </h4>
          <ul>
            <li>
              <p>
                <span className="fw-semibold">Pending booking:  </span>Local didn't accept or decline yet, no payout for them.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold">
                  Confirmed booking or no-show:{" "}
                </span>{" "}
                Experiencer gets full refund if cancelled 24 hours before start time, no refund for cancellations within 24 hours or no-shows. Local gets full payout if cancelled within 24 hours or no-show.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold">Local cancels or no-show: </span>
                Experiencer gets full refund, local gets no payout.
              </p>
            </li>
          </ul>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Booking Requests:
          </h4>
          <p>
            Local must accept or decline within 48 hours, else it expires. If declined or expired, Experiencer won't be charged and local won't receive payouts.
          </p>
          <p>
            If either party is prevented from performing its obligations under the Terms and Conditions due to circumstances beyond its reasonable control, such as acts of God, natural disasters, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemics, epidemics or disease, strikes, shortages of transportation facilities, fuel, energy, labor, or materials, Wareeg shall not be liable for any resulting delay or failure. In such a situation, the affected party will promptly inform the other party and use reasonable commercial efforts to resume performance as soon as it is reasonably practicable.
          </p>
          <p>
            Wareeg reserves the right to make changes to this Cancellation Policy at any time and for any reason, with or without notice. By using Wareeg you agree to this cancellation policy and any changes made in the future
          </p>
          <h4 className="fs-5 mb-3">For Locals:</h4>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Payout:
          </h4>
          <ul>
            <li>
              <p>
                <span className="fw-semibold">Pending booking: </span>Local
                didn't accept or decline yet, no payout for them.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold">
                  Confirmed booking or no-show:{" "}
                </span>
                Experiencer gets full refund if cancelled 24 hours before start
                time, no refund for cancellations within 24 hours or no-shows.
                Local gets full payout if cancelled within 24 hours or no-show.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold">Local cancels or no-show: </span>
                Experiencer gets full refund, local gets no payout.
              </p>
            </li>
          </ul>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Booking Requests:
          </h4>
          <p>
            Local must accept or decline within 48 hours, else it expires. If
            declined or expired, Experiencer won't be charged and local won't
            receive payouts.
          </p>
          <p>
            If either party is prevented from performing its obligations under
            the Terms and Conditions due to circumstances beyond its reasonable
            control, such as acts of God, natural disasters, war, terrorism,
            riots, embargoes, acts of civil or military authorities, fire,
            floods, accidents, pandemics, epidemics or disease, strikes,
            shortages of transportation facilities, fuel, energy, labor, or
            materials, Wareeg shall not be liable for any resulting delay or
            failure. In such a situation, the affected party will promptly
            inform the other party and use reasonable commercial efforts to
            resume performance as soon as it is reasonably practicable.
          </p>
          <p>
            Wareeg reserves the right to make changes to this Cancellation
            Policy at any time and for any reason, with or without notice. By
            using Wareeg you agree to this cancellation policy and any changes
            made in the future.
          </p>
        </div>
      </CollapseFaq>
      <CollapseFaq title="Copyright Policy">
        <div className="p-3">
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Coming Soon!
          </h4>
        </div>
      </CollapseFaq>
      <CollapseFaq title="Delete Account">
        <div className="p-3">
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Account Deletion Policy
          </h4>
          <p>
            This Account Deletion Policy outlines how Wareeg ("we", "us", or "our") handles the deletion of user accounts on our peer-to-peer marketplace platform ("Platform"). We are committed to
            respecting your privacy and complying with applicable data protection laws, including the
            General Data Protection Regulation ("GDPR") and the California Consumer Privacy Act ("CCPA").
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Deleting Your Account
          </h4>
          <p>
            To delete your account, please follow these steps:
            <ol>
              <li><p>Log into the Wareeg app.</p></li>
              <li><p>Navigate to your account settings.</p></li>
              <li><p>Select the option to "Delete Account".</p></li>
              <li><p>Enter your password to confirm the deletion.</p></li>
              <li><p>Click "Delete" to complete the process.</p></li>
              <li><p>Verification of Account Deletion</p></li>
            </ol>
          </p>
          <p>
            Upon successful deletion:
            <ul>
              <li><p>You will no longer be able to log into your account using the app.</p></li>
              <li><p>Wareeg will not send a confirmation email regarding the deletion of your account.</p></li>
            </ul>
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Effects of Account Deletion
          </h4>
          <p>
            Once your account is deleted:
            <ul>
              <li><p>Your profile and all associated personal information will be permanently removed from our Platform.</p></li>
              <li><p>We will no longer use your personal information for any purposes outlined in our Privacy Policy, except as necessary to comply with legal obligations or resolve disputes.</p></li>
              <li><p>We may retain certain information if required by law or for legitimate business purposes, such as fraud prevention or enforcement of our terms and policies.</p></li>
              <li><p>Retention of Data</p></li>
            </ul>
            We retain personal information for as long as necessary to provide our services and fulfill our legal obligations. After account deletion, we securely delete or anonymize your personal
            information as soon as it is no longer needed for the purposes for which it was collected.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Communication
          </h4>
          <p>
            Wareeg will not send a confirmation email upon account deletion. If you have any questions or concerns about the deletion process, please contact us through our <Link to="/contactus">Contact Us </Link>page.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Children's Privacy
          </h4>
          <p>
            Our Platform is not intended for children under the age of 18. If we discover that we have
            inadvertently collected personal information from a child under 18, we will take steps to delete the information as soon as possible.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Changes to this Policy
          </h4>
          <p>
            We may update this Account Deletion Policy from time to time to reflect changes in our privacy practices. We encourage you to review this policy periodically for any updates. We will notify you of any material changes through our Platform.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Contact Us
          </h4>
          <p>
            If you have any questions or concerns, please contact us through our <Link to="/contactus">Contact Us </Link>page.
          </p>
        </div>
      </CollapseFaq>
      <CollapseFaq title="Community Guidelines">
        <div className="p-3">
          <p>
            At Wareeg, we aim to create a community of respectful, trustworthy,
            and enjoyable experiences. In order to ensure that all users have a
            positive experience, we ask that all users abide by the following
            guidelines:
          </p>
          <ol>
            <li>
              <p>
                <span className="fw-semibold" style={{ color: "#4189dd" }}>
                  Respect Others:{" "}
                </span>
                Treat everyone you interact with on Wareeg with respect and
                kindness. Avoid any behavior that could be considered harassing,
                threatening, or discriminatory.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold" style={{ color: "#4189dd" }}>
                  Honest-Communication:{" "}
                </span>{" "}
                transparent and honest in all your communications with other
                users. If you need to cancel an experience, please do so as soon
                as possible and communicate clearly with the organizer.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold" style={{ color: "#4189dd" }}>
                  Respectful and Appropriate Content:{" "}
                </span>
                Do not post any content that is abusive, harmful, sexual,
                illicit, illegal, or otherwise inappropriate. All content should
                be respectful and in line with our community standards.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold" style={{ color: "#4189dd" }}>
                  Safety First:{" "}
                </span>
                Your safety and the safety of others is always the top priority.
                If you have any concerns about your safety during an experience,
                please reach out to Wareeg support.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold" style={{ color: "#4189dd" }}>
                  Respect Property:{" "}
                </span>
                Do not damage or destroy any property while participating in an
                experience. Be mindful of your surroundings and leave the
                location of the experience in the same condition as when you
                arrived.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold" style={{ color: "#4189dd" }}>
                  Solicitation:{" "}
                </span>
                Do not engage in any illegal activities or solicit the use of
                illegal substances, and do not offer to drive guests or hosts to
                or from an experience. Any kind of solicitation, whether before,
                during or after an experience, is not allowed on our platform.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold" style={{ color: "#4189dd" }}>
                  No Off-Platform Communication:{" "}
                </span>
                Do not make any bookings, arrangements, or communications
                between experiencer or local outside of the Wareeg platform.
                This is to ensure safety and security for all users and maintain
                the integrity of the Wareeg platform.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold" style={{ color: "#4189dd" }}>
                  Report Unacceptable Behavior:{" "}
                </span>
                If you encounter any behavior that violates these community
                guidelines or is otherwise unacceptable, please report it to
                Wareeg support.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold" style={{ color: "#4189dd" }}>
                  Follow Laws and Regulations:{" "}
                </span>
                Ensure that your actions while participating in an experience
                are in compliance with all applicable laws and regulations.
              </p>
            </li>
            <li>
              <p>
                <span className="fw-semibold" style={{ color: "#4189dd" }}>
                  Trust and Fairness:{" "}
                </span>
                Wareeg operates on a trust-based system, and we expect all users
                to act fairly and honor their commitments. If you believe that
                another user has acted in a manner that violates this principle,
                please report it to Wareeg support.
              </p>
            </li>
          </ol>
          <p>
            Wareeg reserves the right to remove any user who violates these
            community guidelines, and to take other actions as necessary to
            maintain a safe and enjoyable experience for all users.
          </p>
          <p>
            By using Wareeg, you agree to abide by these community guidelines
            and to act in accordance with the principles of trust, respect, and
            safety. Thank you for your cooperation!
          </p>
        </div>
      </CollapseFaq>
      <CollapseFaq title="Privacy Policy">
        <div className="p-3">
          <p>
            This Privacy Policy ("Policy") outlines how Wareeg ("we", "us", or
            "our") collects, uses, and protects the personal information of
            users ("Users") of our peer-to-peer marketplace platform
            ("Platform") that facilitates experiencers ("Guests") to book locals
            ("Hosts") to host a private experience anytime, anywhere. This
            Policy is in compliance with the General Data Protection Regulation
            ("GDPR") and the California Consumer Privacy Act ("CCPA").
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Information We Collect
          </h4>
          <p>
            We collect<span style={{ color: "red" }}>*</span> personal information when Users register an account or
            use the Platform, including:
          </p>
          <ul>
            <li>
              <p>
                Contact information: name, email address, phone number, and
                location
              </p>
            </li>
            <li>
              <p>
                Personal information: SSN, ID, DOB, residential address, and
                availability
              </p>
            </li>
            <li>
              <p>
                Payment information: payment card details or direct deposit
                information
              </p>
            </li>
            <li>
              <p>
                Profile information: profile picture, interests, languages,
                hosting city, and bio
              </p>
            </li>
            <li>
              <p>
                Communication information: any correspondence Users have with
                each other through the Platform
              </p>
            </li>
          </ul>
          <p>
            <span style={{ color: "red" }}>*</span>The information you will be
            asked to input during registration may vary depending on whether you
            are registering for a guest or host account/profile.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            How We Use Your Information
          </h4>
          <p>We use personal information for the following purposes:</p>
          <ul>
            <li>To provide and improve our Platform</li>
            <li>To verify User identity and prevent fraudulent activity</li>
            <li>
              To communicate with Users about their experiences and bookings
            </li>
            <li>To process payments, payouts and refunds</li>
            <li>
              To personalize User experience and match Users with compatible
              hosts or guests
            </li>
            <li>To comply with legal obligations, including GDPR and CCPA</li>
          </ul>
          <p>
            We will not use your personal information for marketing purposes
            without your consent.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Information Sharing
          </h4>
          <p>
            We may share personal information with the following third-party
            service providers:
          </p>
          <ul>
            <li>
              Payment processing companies to process payments and payouts
            </li>
            <li>Hosting providers to host the Platform</li>
            <li>Customer service providers to assist with user support</li>
            <li>
              Communication providers to enable communication between Users
            </li>
          </ul>
          <p>
            We may also share personal information with law enforcement or
            government agencies in response to a valid legal request.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Cookies
          </h4>
          <p>
            We use cookies and similar technologies to enhance User experience
            on our Platform. Cookies are small data files that are placed on
            your device. They may be used to store your preferences or track
            your activity on our Platform. You may opt-out of cookies by
            adjusting your browser settings.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Data Retention
          </h4>
          <p>
            We retain personal information for as long as necessary to provide
            our Platform and fulfill our legal obligations. We will securely
            delete personal information when it is no longer needed.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Security
          </h4>
          <p>
            We take appropriate measures to protect personal information against
            unauthorized access, alteration, or destruction. We restrict access
            to personal information to employees and contractors who need to
            know the information to provide our services.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Your Rights
          </h4>
          <p>
            You have the right to access, correct, or delete your personal
            information. You may also request that we restrict the processing of
            your personal information or transfer it to another party. To
            exercise these rights, please contact us using the contact
            information below.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Children's Privacy
          </h4>
          <p>
            Our Platform is not intended for use by children under the age of
            18. We do not knowingly collect personal information from children
            under 18. If you become aware that a child under 18 has provided us
            with personal information, please contact us using the contact
            information below.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Changes to this Policy
          </h4>
          <p>
            We may update this Policy from time to time to reflect changes in
            our privacy practices. We will notify Users of any material changes
            to this Policy by email or through our Platform.
          </p>
          <p>
            {/* <Link to="/"> */}
            Contact Us If you have any questions or concerns about this
            Policy, please contact us at&nbsp;
            {/* </Link> */}
            <a href="https://www.wareeg.com/" target="_blank">
              wareeg.com.
            </a>
          </p>
        </div>
      </CollapseFaq>
      <CollapseFaq title="Refund Policy">
        <div className="p-3">
          <p>
            At Wareeg, we strive to ensure that all experiences booked through
            our platform meet the expectations of our Experiencers. However, we
            understand that there may be situations where a refund is necessary.
            To ensure a fair and consistent policy for all parties, we have
            established the following guidelines:
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Refund Requests:
          </h4>
          <p>
            If an Experiencer faces cancellations or no-shows while booking an
            experience through Wareeg, they may be eligible for a refund. To
            check the status of their refund, Experiencers can submit a
            submission through wareeg.io.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Grounds for Refund:
          </h4>
          <p>
            We will consider refund requests based on the following grounds:
          </p>
          <ul>
            <li>
              <p>
                The experiencer cancels a reservation up to 24 hours before the
                start time.
              </p>
            </li>
            <li>The local did not show up for the scheduled experience.</li>
            <li>The local canceled the experience before the start time.</li>
          </ul>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Refund Amount:
          </h4>
          <p>
            If we determine that an Experiencer is entitled to a refund, we will
            issue a refund for the full amount paid minus any fees charged by
            Wareeg.
          </p>
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Refund Processing:
          </h4>
          <p>
            Refunds will be processed via the payment method used during
            booking, with processing times varying due to various factors.
          </p>
          <p>
            Wareeg reserves the right to make changes to this Refund Policy at
            any time and for any reason, with or without notice. By using
            Wareeg, you agree to this refund policy and any changes made in the
            future.
          </p>
        </div>
      </CollapseFaq>
      <CollapseFaq title="Terms and Conditions">
        <p>
          These terms and conditions ("Terms") govern the use of the Wareeg
          platform ("Platform") by users ("Users"). The Platform is a
          peer-to-peer marketplace that facilitates experiencers ("Guests") to
          book locals ("Hosts") to host a private experience anytime, anywhere.
          By accessing or using the Platform, Users agree to be bound by these
          Terms. These Terms constitute a legal agreement between Users and
          Wareeg ("we", "us", or"our").
        </p>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          1. Definitions:
        </h4><>
          <p>
            1.1. "Experiencers"refer to Guests who use the Platform to book an
            Experience with a Host.
          </p>
          <p>
            1.2. "Locals"refer to Hosts who use the Platform to offer an
            Experience to Guests.
          </p>
          <p>
            1.3. “Platform” refer to the Wareeg website and mobile application,
            and any related services, features, and content.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          2. Use of the Platform
        </h4>
        <>
          <p>
            2.1. Users must be at least 18 years of age to use the Platform.
          </p>
          <p>
            2.2. Users agree to provide accurate and complete information
            during registration and to keep their profile information
            up-to-date.
          </p>
          <p>
            2.3. Users are responsible for maintaining the confidentiality of
            their account information and for any activity that occurs
            under their account.
          </p>
          <p>
            2.4. Users may not use the Platform for any unlawful or fraudulent
            activity.
          </p>
          <p>
            2.5. Users agree to comply with all applicable laws and
            regulations, including but not limited to GDPR and CCA.
          </p>
          <p>
            2.6. Users agree to respect the privacy and personal data of other
            Users and to comply with our Privacy Policy.
          </p>
          <p>
            2.7. Users agree not to use the Platform to harass, defame, or
            discriminate against other Users.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          3. Safety
        </h4>
        <>
          <p>
            3.1. Users are responsible for their own safety during an
            Experience.
          </p>
          <p>
            3.2. Hosts are not permitted to offer or accept transportation of
            any kind. Users should have their own transportation.
          </p>
          <p>
            3.3. Hosts are responsible for ensuring that their Experience
            complies with all applicable laws and regulations related to
            safety.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          4. Booking and Payment
        </h4>
        <>
          <p>
            4.1. Guests may book an Experience with a Host through the
            Platform.
          </p>
          <p>
            4.2. Guests agree to pay the full amount of the booking including
            service fee at the time of booking through the Platform and
            will be held in escrow until the experience has been
            completed.
          </p>
          <p>
            4.3. The service fee collected by Wareeg is included in the total
            amount at the time of booking.
          </p>
          <p>
            4.4. Hosts receive payment for their Experience through the
            Platform, minus our service fee.
          </p>
          <p>
            4.5. Hosts agree to provide the Experience as described in their
            profile and to communicate with the Guest to plan the details
            of the Experience.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          5. Communication
        </h4>
        <>
          <p>
            5.1. All communication between Experiencers and Locals must occur
            through the Platform.
          </p>
          <p>
            5.2. Users may communicate with each other through the Platform to
            plan the details of the Experience.
          </p>
          <p>
            5.3. Users are encouraged to use the Platform to document their
            communication and to report any issues or complaints.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          6. Reviews and Ratings
        </h4>
        <>
          <p>
            6.1. Guests may leave a review and rating of their Experience with
            a Host.
          </p>
          <p>
            6.2. Hosts may leave a review and rating of their interaction with
            a Guest.
          </p>
          <p>
            6.3. Reviews and ratings must be honest and based on actual
            experiences.
          </p>
          <p>
            6.4. We reserve the right to remove reviews and ratings that
            violate these Terms.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          7. Cancellation and Refunds
        </h4>
        <>
          <p>
            7.1.  Guests may cancel their booking up to 24 hours before the
            scheduled start time for a full refund minus any applicable
            fees.
          </p>
          <p>
            7.2. Hosts may cancel a booking at any time, if host decides to do
            so, they will forfeit any payout associated with that booking.
          </p>
          <p>
            7.3. If a Host fails to show up for a scheduled Experience, they
            will not be eligible for any payout.
          </p>
          <p>
            7.4. If a Guest fails to show up for a scheduled Experience, they
            will not be entitled to a refund.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          8. Hosting on Wareeg
        </h4>
        <>
          <p>
            8.1. Hosts may not ask Guests to pay them or make arrangements on
            their behalf outside of the Platform.
          </p>
          <p>
            8.2. For Experiences involving prior booking, Hosts should direct
            Guests with a link to the venue or activity and have them pay
            on their own. Otherwise, Guests should pay at the location at
            the time of the Experience.
          </p>
          <p>
            8.3. Hosts are independent contractors and nothing is withheld from
            their pay.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          9. Experience Details
        </h4>
        <>
          <p>
            9.1. Hosts are responsible for providing accurate and complete
            information about their Experience, including the date, time,
            location, and description.
          </p>
          <p>
            9.2. Guests are responsible for reviewing and confirming the
            details of the Experience before booking.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          10. Issues and Complaints
        </h4>
        <>
          <p>
            10.1. Users are encouraged to communicate directly with each other
            to resolve any issues or complaints that may arise during or
            after an Experience.
          </p>
          <p>
            10.2. If a User is unable to resolve an issue or complaint with the
            other party, they may contact Wareeg for assistance.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          11. Prohibited Conduct
        </h4>
        <>
          11.1. Users may not use the Platform to engage in any prohibited
          conduct, including but not limited to:
          <>
            <p className="ps-2"> 11.1.1. Harassment or discrimination of any kind</p>
            <p className="ps-2">11.1.2. Fraudulent or unlawful activity</p>
            <p className="ps-2">11.1.3. Posting false or misleading information</p>
            <p className="ps-2">11.1.4. Violating the intellectual property rights of others</p>
          </>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          12. Reporting Violations
        </h4>
        <>
          <p>
            12.1. Users are encouraged to report any violations of these Terms
            or any unlawful activity to Wareeg.
          </p>
          <p>
            12.2. We will investigate all reports of violations and take
            appropriate action.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          13. Indemnity
        </h4>
        <>
          <p>
            13.1. Users agree to indemnify and hold Wareeg, its affiliates,
            officers, agents, employees, and partners harmless from and
            against any and all claims, liabilities, damages, losses, and
            expenses, including reasonable attorneys'fees and costs,
            arising out of or in any way connected with:
          </p>
          <>
            <p className="ps-2">13.1.1. Users’ use of the Platform;</p>
            <p className="ps-2">13.1.2. Users’ violation of these Terms;</p>
            <p className="ps-2">13.1.3. Users’ violation of any rights of another party;</p>
            <p className="ps-2">13.1.4. Users’ violation of any law or regulation;</p>
            <p className="ps-2">
              13.1.5. Users’ interaction with any other User or third party,
              including but not limited to Experiencers (Guests) and
              Locals (Hosts);
            </p>
            <p className="ps-2">
              13.1.6. Any content or information posted or transmitted by Users
              on or through the Platform;
            </p>
            <p className="ps-2">
              13.1.7. Any dispute or issue that arises between Users or with a
              third party, including but not limited to any personal
              injury, death, or property damages that occurs during an
              Experience;
            </p>
            <p className="ps-2">
              13.1.8. Any claims made by a third party that the content or
              information posted or transmitted by Users on or through
              the Platform infringes on their intellectual property
              rights;
            </p>
            <p className="ps-2">
              13.1.9. Any claim that Wareeg is responsible for any taxes,
              duties, or fees related to any transaction or payment made
              through the Platform; or
            </p>
            <p className="ps-2">
              13.1.10. Any claim that Wareeg has failed to comply with any
              applicable law or regulation.
            </p>
          </>
          <p>
            13.2. This indemnification provision shall survive the termination
            of these Terms and Users'use of the Platform. Users agree to
            cooperate fully with Wareeg in the defense of any such claim.
            Wareeg reserves the right, at its own expense, to assume the
            exclusive defense and control of any matter subject to
            indemnification by Users.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          14. Limitation of Liability
        </h4>
        <>
          <p>
            14.1. By using the Wareeg platform, Users acknowledge and agree that
            Wareeg is not liable for any direct, indirect, incidental,
            special, consequential, or punitive damages arising out of or
            relating to these Terms or the use of the Platform. This
            includes, but is not limited to, any damages arising from
            personal injury, property damage, lost profits, loss of data,
            or business interruption.
          </p>
          <p>
            14.2. Users agree that they assume all risk associated with using
            the Platform and booking or participating in an Experience.
            Wareeg is not responsible for the quality, safety, or legality
            of any Experience offered by Hosts. Users acknowledge and
            agree that any bookings and participation in Experiences are
            done at their own risk.
          </p>
          <p>
            14.3. In no event shall Wareeg be liable for any damages, whether in
            contract, tort, strict liability, or otherwise, arising from
            or in connection with the use of the Platform or any
            Experience, even if Wareeg has been advised of the possibility
            of such damages.
          </p>
          <p>
            14.4. Users agree to indemnify, defend, and hold Wareeg and its
            affiliates, officers, agents, and employees harmless from any
            claims,damages, or losses arising from their use of the
            Platform or participation in any Experience.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          15. Termination
        </h4>
        <>
          <p>
            15.1. We may terminate a User's account at any time for any reason,
            including but not limited to, a violation of these Terms and
            Conditions or a violation of applicable laws.
          </p>
          <p>
            15.2. In the event that a Local's account is terminated, any
            existing bookings will be cancelled and any payments will be
            refunded to the Experiencer per our cancellation policy.
          </p>
          <p>
            15.3. Hosts acknowledge that they may be rejected from hosting an
            experience at any time, regardless of whether a booking has
            already been made, and acknowledge that this may disrupt any
            existing bookings. Wareeg will not guarantee the availability
            of a replacement host or the continuity of the experience.
          </p>
          <p>
            15.4. Upon termination, Users will no longer have access to the
            Platform.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          16. Legal Mandates
        </h4>
        <>
          <p>
            16.1. We may be required to cooperate with law enforcement or other
            jurisdictions in investigating unlawful activity or violations
            of these Terms.
          </p>
          <p>
            16.2. We reserve the right to disclose any information necessary to
            comply with legal mandates or to protect the safety and
            security of our Users.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          17. Copyright Infringement
        </h4>
        <>
          <p>
            17.1. We respect the intellectual property rights of others and
            expect our Users to do the same.
          </p>
          <p>
            17.2. We will investigate all reports of copyright infringement and
            take appropriate action.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          18. Intellectual Property
        </h4>
        <>
          <p>
            18.1. Users retain ownership of their own intellectual property
            rights.
          </p>
          <p>
            18.2. Users grant us a non-exclusive, royalty-free, transferable,
            sub-licensable, worldwide license to use any content they
            upload to the Platform.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          19. Arbitration and Class Action Waiver
        </h4>
        <>
          <p>
            19.1. Any disputes arising out of or relating to these Terms will be
            resolved through binding arbitration.
          </p>
          <p>
            19.2. Users waive the right to participate in any class action
            lawsuit or class-wide arbitration.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          20. Modification
        </h4>
        <>
          <p> 20.1. We reserve the right to modify these Terms at any time.</p>
          <p>
            20.2. Users will be notified of any changes to these Terms via the
            Platform or by email.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          21. Force Majeure
        </h4>
        <>
          <p>
            21.1. If either party is prevented from performing its obligations
            under these Terms and Conditions due to circumstances beyond
            its reasonable control, such as acts of God, natural
            disasters, war, terrorism, riots, embargoes, acts of civil or
            military authorities, fire, floods, accidents, pandemics,
            epidemics or disease, strikes, shortages of transportation
            facilities, fuel, energy, labor, or materials, Wareeg shall
            not be liable for any resulting delay or failure. In such a
            situation, the affected party will promptly inform the other
            party and use reasonable commercial efforts to resume
            performance as soon as it is reasonably practicable.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          22. General Provisions
        </h4>
        <>
          <p>
            22.1. These Terms constitute the entire agreement between Users and
            Wareeg.
          </p>
          <p>
            22.2. These Terms shall be governed by and construed in accordance
            with the laws of the State of Delaware where Wareeg
            Corporation is based.
          </p>
          <p>
            22.3. sers agree to resolve any disputes arising out of or relating
            to these Terms.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          23. Miscellaneous
        </h4>
        <>
          <p>
            23.1. These Terms do not create any agency, partnership, or joint
            venture relationship between Users and Wareeg.
          </p>
          <p>
            23.2. If any provision of these Terms is found to be invalid or
            unenforceable, the remaining provisions will remain in effect.
          </p>
          <p>23.3. These Terms are binding.</p>
        </>
      </CollapseFaq>
      <CollapseFaq title="Trademark Guidelines">
        <div className="p-3">
          <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
            Coming Soon!
          </h4>
        </div>
      </CollapseFaq>
    </SupportPageLayout >
  );
};

export default Legal;

import { useEffect, useRef, useState } from "react";
import axios from "axios";
import ContactForm from "../Components/ContactUs/ContactForm"
import { isMobile } from "react-device-detect";
import ToggleSwitch from "../Components/ContactUs/ToggleSwitch";
import ContactStyle from "./Contact.module.css";
import { useNavigate } from "react-router-dom";

const Contactus = () => {
  const [isExperiencer, setIsExperiencer] = useState(true);
  const [localtype, setLocaltype] = useState("local");
  const [isNotEmpty, setIsNotEmpty] = useState(false);
  const [type, setType] = useState("date");
  const navigate = useNavigate();
  
  const [selectOpen, setSelectOpen] = useState(false);
  const [disablebtn, setDisablebtn] = useState(false);
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    bookingId: "",
    reason: "",
    zipCode: "",
    message: "",
  });
  const [error, setError] = useState("");

  const [errorMessages, setErrorMessages] = useState({
    firstNameErrMsg: "Only Alphabets Allowed",
    lastNameErrMsg: "Only Alphabets Allowed",
    emailErrMsg: "Enter Valid Email",
    dobErrMsg: "Enter Valid Dob",
    bookingIdErrMsg: "Enter Valid Booking Id",
    zipCodeErrMsg: "Enter Valid Zip Code",
  });
  // const reasonRef = useRef();

  const [errMsgValid, setErrMsgValid] = useState({
    isFirstName: false,
    isLastName: false,
    isEmail: false,
    isDob: false,
    isBookingId: false,
    isZipCode: false,
  });

  const [disabled, setDisabled] = useState(true);
  const nameIsValid = details.firstName.trim() !== "";
  const lastnameIsValid = details.lastName.trim() !== "";
  const emailIsValid = details.email.trim() !== "";
  const dobIsValid = details.dob.trim() !== "";
  const bookingIdIsValid = details.bookingId.trim() !== "";
  const reasonIsValid = details.reason.trim() !== "";
  const zipCodeIsValid = details.zipCode.trim() !== "";
  const messageIsValid = details.message.trim() !== "";
  let validregex =
    /^([a-zA-Z0-9.-]+)@([a-zA-Z0-9-]+).([a-z]{2,8})(.[a-z]{1,8})$/;

  // const validemail=details.email.match(validregex);
  const validemail = validregex.test(details.email);
  let validCodeRegex = "/^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/";
  const regex = /^\d{5}$/;
  const validZipCode = regex.test(details.zipCode);
  const regex1 = /^[A-Za-z0-9]{9}$/;
  const validBookingId = regex1.test(details.bookingId);

  useEffect(() => {
    if (!validemail && emailIsValid) {
      setErrMsgValid({ ...errMsgValid, isEmail: true });
    } else {
      setErrMsgValid({ ...errMsgValid, isEmail: false });
    }
    if (!validBookingId && bookingIdIsValid) {
      setErrMsgValid({ ...errMsgValid, isBookingId: true });
    } else {
      setErrMsgValid({ ...errMsgValid, isBookingId: false });
    }
    if (
      nameIsValid &&
      lastnameIsValid &&
      emailIsValid &&
      dobIsValid &&
      reasonIsValid &&
      zipCodeIsValid &&
      messageIsValid &&
      validemail &&
      validZipCode &&
      bookingIdIsValid &&
      validBookingId
    ) {
      setDisabled(false);
    } else if (
      details.reason !== "Complaint" &&
      details.reason !== "Refund" &&
      details.reason !== "Payout"
    ) {
      if (details.reason === "Other") {
        if (
          details.firstName !== "" &&
          details.lastName !== "" &&
          details.dob !== "" &&
          details.zipCode !== "" &&
          details.reason !== "" &&
          details.message !== ""
        ) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else if (
        details.firstName !== "" &&
        details.lastName !== "" &&
        details.email !== "" &&
        details.dob !== "" &&
        details.zipCode !== "" &&
        details.reason !== "" &&
        details.message !== ""
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [
    nameIsValid,
    lastnameIsValid,
    emailIsValid,
    dobIsValid,
    reasonIsValid,
    zipCodeIsValid,
    messageIsValid,
    validemail,
    validZipCode,
    errMsgValid.isEmail,
    bookingIdIsValid,
    validBookingId,
    details,
  ]);
  useEffect(() => {
    if (isExperiencer) {
      setLocaltype("experiencer");
      setDetails({ ...details, reason: "" });
      // reasonRef.current.value = "";
    } else {
      setLocaltype("local");
      setDetails({ ...details, reason: "" });
      // reasonRef.current.value = "";
    }
  }, [isExperiencer]);

  const handleInputChange = (e) => {
    const name = e.target.name;
    var value = e.target.value;
    if (name === "dob") {
      let val = value.split("-");
      let val1 = [val[1], val[2], val[0]];
      let val2 = val1.join("-");
      value = val2;
      if (!isMobile) {
        setTimeout(() => {
          setType("text");
          setDisablebtn(true);
        }, 2000);
      }
    }
    setDetails((values) => ({ ...values, [name]: value }));
    if (name === "zipCode") {
      const regex = /^\d{5}$/;
      const validCode = regex.test(value);
      if (!validCode) {
        setErrMsgValid({ ...errMsgValid, isZipCode: true });
      } else {
        setErrMsgValid({ ...errMsgValid, isZipCode: false });
      }
    }
    if (name === "email") {
      let validregex =
        /^([a-zA-Z0-9.-]+)@([a-zA-Z0-9-]+).([a-z]{2,8})(.[a-z]{1,8})$/;

      const validemail = validregex.test(value);
      if (!validemail) {
        setErrMsgValid({ ...errMsgValid, isEmail: true });
      } else {
        setErrMsgValid({ ...errMsgValid, isEmail: false });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const detailsVal = details;
    const newValues = { ...detailsVal, usertype: localtype };
    saveDetails(newValues);
  };
  const saveDetails = (e) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/contact`, e, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_USERNAME +
              ":" +
              process.env.REACT_APP_PASSWORD
            ),
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          navigate("/thankyou");
        } else if (res.data.status === 400) {
          setError(res.data.error);
          window.alert(res.data.error.errorCode);
        } else if (res.data.status === 401) {
        }
        // alert(res);
      });
  };
  return (
    <>
      <section className={ContactStyle.contactUs}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-xl-11">
              <div>
                <h1 className={ContactStyle.title}>Contact Us</h1>
              </div>
              <ToggleSwitch
                isExperiencer={true}
                setIsExperiencer={(value) => setIsExperiencer(value)}
              />
              <ContactForm
                details={details}
                disabled={disabled}
                handleSubmit={handleSubmit}
                handleInputChange={handleInputChange}
                setDetails={setDetails}
                disablebtn={disablebtn}
                selectOpen={selectOpen}
                isExperiencer={isExperiencer}
                setSelectOpen={setSelectOpen}
                errMsgValid={errMsgValid}
                setType={setType}
                type={type}
                setDisablebtn={setDisablebtn}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contactus;

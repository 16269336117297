import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const CopyRight = () => {
  return (
    <Col sm="12">
      <div className="hstack gap-3">
        <div className="text-white fs-6">
          <small>© {new Date().getFullYear()} Wareeg Corporation</small>
        </div>
        <div className="ms-auto">
          <Link className="text-white text-decoration-none" to="/privacy">
            <small>Privacy</small>
          </Link>
        </div>
        <div>
          <Link className="text-white text-decoration-none" to="/terms">
            <small>Terms</small>
          </Link>
        </div>
      </div>
    </Col>
  );
};

export default CopyRight;

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'


const SupportPageLayout = ({ children }) => {
  return (
    <section className='terms-pages py-5'>
      <Container>
        <Row>
          <Col sm="12">{children}</Col>
        </Row>
      </Container>
    </section>
  )
}

export default SupportPageLayout
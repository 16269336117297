import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import CloseIcon from "../Components/ContactUs/CloseIcon";
import styles from "./SignUp.module.css";
import wareeg from "../assets/wareegBlue.svg";
import Thankyou from "../Components/Thankyou";
import FormSignUp from "../Components/FormSignUp";

const SignUp = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  return (
    <section className={styles.signup}>
      <Container>
        <Row className="justify-content-center">
          <Col sm="11" md="8" lg="7" xl="6">
            <Card className="rounded-4 shadow-lg border border-0">
              <div
                className={styles.close}
                onClick={() => {
                  navigate("/");
                }}
              >
                <CloseIcon />
              </div>
              <Card.Body className="p-3 p-xl-5">
                <div className="mb-3">
                  <img src={wareeg} alt="Wareeg logo" />
                </div>
                <h4 className={styles.title}>Get Early Access</h4>
                <p>Fill in details below to access amazing features.</p>
                <FormSignUp modalHandler={setShow} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Thankyou show={show} modalHandler={setShow} />
    </section>
  );
};

export default SignUp;

import React from 'react';
import styles from './HeroBanner.module.css';
import { Col, Container, Row } from 'react-bootstrap';

const HeroBanner = () => {
  return (
    <section className={`${styles.hero_banner} mb-5`}>
        <Container fluid className='position-relative' style={{zIndex: '2'}}>
            <Row className='justify-content-center'>
                <Col sm="12" md="8" lg="7" xl="6">
                    <h1 className='fs-1 fw-semibold text-white'>Experience your world, your<br/>  way - join the world's first<br/> Experience-Sharing platform!</h1>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default HeroBanner
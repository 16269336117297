import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const Slider = ({items}) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} style={{borderRadius: '1rem', overflow: 'hidden'}}>
        {items.map((item) => {
            return <Carousel.Item key={item.id} interval={1000}>
                <img src={item.src} alt={item.name} className="img-fluid" />
            </Carousel.Item>
        })}
    </Carousel>
  )
};

export default Slider;

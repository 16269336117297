import React from "react";
import HeroBanner from "../Components/Home/HeroBanner";
import AboutUs from "../Components/Home/AboutUs";
import HowItWorks from "../Components/Home/HowItWorks";
import CostSaving from "../Components/Home/CostSaving";
import { useLocation } from "react-router-dom";

const Home = ({ local }) => {
  const location = useLocation();
  const { state } = location;

  const fromHowItWorks = state && state.fromHowItWorks;
  return (
    <>
      <HeroBanner />
      <AboutUs />
      <HowItWorks fromHowItWorks={fromHowItWorks} local={local} />
      <CostSaving />
    </>
  );
};

export default Home;

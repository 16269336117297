import React, { useState } from "react";
import styles from './Collapse.module.css';
import downArrow from '../../assets/wareeg/downArrow.svg';
import closeIcon from '../../assets/wareeg/closeIcon.svg';

const CollapseFaq = ({ children, title }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="d-flex flex-column border-bottom">
      <h2 onClick={() => {setIsActive(!isActive)}} className="d-flex justify-content-between py-3 fs-4 fw-normal" role="button">
        <span>{title}</span>
        <span>
          <img src={isActive ? closeIcon : downArrow} alt="down arrow" />
        </span>
      </h2>
      <div className={isActive ? styles.faq_show : styles.faq_hide}>
        {children}
      </div>
    </div>
  );
};

export default CollapseFaq;

import React from "react";
import styles from "./SectionTitle.module.css";

const SectionTitle = ({ text }) => {
  return (
    <>
      <div className={styles.title}>{text.toUpperCase()}</div>
    </>
  );
};

export default SectionTitle;

import React from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import CommonLayout from "./CommonLayout";

const Header = () => {
  const navigate = useNavigate()
  const navigateToHowItWorks = () => {
    navigate("/", { state: { fromHowItWorks: true } });
    setTimeout(() => {
      navigate("/", { state: {} });
    }, 1000);
  };

  return (
    <header className={`${styles.header} py-2`}>
      <Navbar expand="lg">
        <Container>
          <Link className="navbar-brand">
            <Logo />
          </Link>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto align-items-center">
              <button onClick={navigateToHowItWorks} className={`${styles.nav_link} nav-link py-3 py-lg-0 me-lg-5`}>How it Works</button>
              <Link to="/contactus" className={`${styles.nav_link} nav-link py-3 py-lg-0 me-lg-5 mb-3 mb-lg-0 `}>
                Contact Us
              </Link>
              <Link to="/signup" className={styles.nav_btn}>
                Sign Up
              </Link>
            </Nav>
          </Navbar.Collapse>

        </Container>
      </Navbar>
    </header >
  );
};

export default Header;

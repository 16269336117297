import slider1 from "./assets/sliderImages/experiencer/Component21.png";
import slider2 from "./assets/sliderImages/experiencer/Component22.png";
import slider3 from "./assets/sliderImages/experiencer/Component23.png";
import slider4 from "./assets/sliderImages/experiencer/Component24.png";
import slider5 from "./assets/sliderImages/experiencer/Component25.png";
import slider6 from "./assets/sliderImages/experiencer/Component26.png";
import slider7 from "./assets/sliderImages/experiencer/Component27.png";
import slider8 from "./assets/sliderImages/experiencer/Component28.png";
import slider9 from "./assets/sliderImages/local/20_Local_Dashboard.png";
import slider10 from "./assets/sliderImages/local/21tour.png";
import slider11 from "./assets/sliderImages/local/22_payment.png";
import slider12 from "./assets/sliderImages/local/23Local_Dashboard.png";
import slider13 from "./assets/sliderImages/local/24_payment.png";
import slider14 from "./assets/sliderImages/local/25_payment.png";

export const sliderExperience = [
    {id:1,name: 'App Screes', src: slider1},
    {id:2,name: 'App Screes', src: slider2},
    {id:3,name: 'App Screes', src: slider3},
    {id:4,name: 'App Screes', src: slider4},
    {id:5,name: 'App Screes', src: slider5},
    {id:6,name: 'App Screes', src: slider6},
    {id:7,name: 'App Screes', src: slider7},
    {id:8,name: 'App Screes', src: slider8}
]

export const sliderLocal = [
    {id:1,name: 'App Screes', src: slider9},
    {id:2,name: 'App Screes', src: slider10},
    {id:3,name: 'App Screes', src: slider11},
    {id:4,name: 'App Screes', src: slider12},
    {id:5,name: 'App Screes', src: slider13},
    {id:6,name: 'App Screes', src: slider14},
]

import React from 'react'
import SupportPageLayout from '../Components/SupportPageLayout'
import SupportPageTitle from '../Components/SupportPageTitle'

const Terms = () => {
  return (
    <SupportPageLayout>
      <SupportPageTitle text="Terms and Conditions" />
      <div >
        <p>
          These terms and conditions ("Terms") govern the use of the Wareeg
          platform ("Platform") by users ("Users"). The Platform is a
          peer-to-peer marketplace that facilitates experiencers ("Guests") to
          book locals ("Hosts") to host a private experience anytime, anywhere.
          By accessing or using the Platform, Users agree to be bound by these
          Terms. These Terms constitute a legal agreement between Users and
          Wareeg ("we", "us", or"our").
        </p>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          1. Definitions:
        </h4><>
          <p>
            1.1. "Experiencers"refer to Guests who use the Platform to book an
            Experience with a Host.
          </p>
          <p>
            1.2. "Locals"refer to Hosts who use the Platform to offer an
            Experience to Guests.
          </p>
          <p>
            1.3. “Platform” refer to the Wareeg website and mobile application,
            and any related services, features, and content.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          2. Use of the Platform
        </h4>
        <>
          <p>
            2.1. Users must be at least 18 years of age to use the Platform.
          </p>
          <p>
            2.2. Users agree to provide accurate and complete information
            during registration and to keep their profile information
            up-to-date.
          </p>
          <p>
            2.3. Users are responsible for maintaining the confidentiality of
            their account information and for any activity that occurs
            under their account.
          </p>
          <p>
            2.4. Users may not use the Platform for any unlawful or fraudulent
            activity.
          </p>
          <p>
            2.5. Users agree to comply with all applicable laws and
            regulations, including but not limited to GDPR and CCA.
          </p>
          <p>
            2.6. Users agree to respect the privacy and personal data of other
            Users and to comply with our Privacy Policy.
          </p>
          <p>
            2.7. Users agree not to use the Platform to harass, defame, or
            discriminate against other Users.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          3. Safety
        </h4>
        <>
          <p>
            3.1. Users are responsible for their own safety during an
            Experience.
          </p>
          <p>
            3.2. Hosts are not permitted to offer or accept transportation of
            any kind. Users should have their own transportation.
          </p>
          <p>
            3.3. Hosts are responsible for ensuring that their Experience
            complies with all applicable laws and regulations related to
            safety.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          4. Booking and Payment
        </h4>
        <>
          <p>
            4.1. Guests may book an Experience with a Host through the
            Platform.
          </p>
          <p>
            4.2. Guests agree to pay the full amount of the booking including
            service fee at the time of booking through the Platform and
            will be held in escrow until the experience has been
            completed.
          </p>
          <p>
            4.3. The service fee collected by Wareeg is included in the total
            amount at the time of booking.
          </p>
          <p>
            4.4. Hosts receive payment for their Experience through the
            Platform, minus our service fee.
          </p>
          <p>
            4.5. Hosts agree to provide the Experience as described in their
            profile and to communicate with the Guest to plan the details
            of the Experience.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          5. Communication
        </h4>
        <>
          <p>
            5.1. All communication between Experiencers and Locals must occur
            through the Platform.
          </p>
          <p>
            5.2. Users may communicate with each other through the Platform to
            plan the details of the Experience.
          </p>
          <p>
            5.3. Users are encouraged to use the Platform to document their
            communication and to report any issues or complaints.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          6. Reviews and Ratings
        </h4>
        <>
          <p>
            6.1. Guests may leave a review and rating of their Experience with
            a Host.
          </p>
          <p>
            6.2. Hosts may leave a review and rating of their interaction with
            a Guest.
          </p>
          <p>
            6.3. Reviews and ratings must be honest and based on actual
            experiences.
          </p>
          <p>
            6.4. We reserve the right to remove reviews and ratings that
            violate these Terms.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          7. Cancellation and Refunds
        </h4>
        <>
          <p>
            7.1.  Guests may cancel their booking up to 24 hours before the
            scheduled start time for a full refund minus any applicable
            fees.
          </p>
          <p>
            7.2. Hosts may cancel a booking at any time, if host decides to do
            so, they will forfeit any payout associated with that booking.
          </p>
          <p>
            7.3. If a Host fails to show up for a scheduled Experience, they
            will not be eligible for any payout.
          </p>
          <p>
            7.4. If a Guest fails to show up for a scheduled Experience, they
            will not be entitled to a refund.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          8. Hosting on Wareeg
        </h4>
        <>
          <p>
            8.1. Hosts may not ask Guests to pay them or make arrangements on
            their behalf outside of the Platform.
          </p>
          <p>
            8.2. For Experiences involving prior booking, Hosts should direct
            Guests with a link to the venue or activity and have them pay
            on their own. Otherwise, Guests should pay at the location at
            the time of the Experience.
          </p>
          <p>
            8.3. Hosts are independent contractors and nothing is withheld from
            their pay.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          9. Experience Details
        </h4>
        <>
          <p>
            9.1. Hosts are responsible for providing accurate and complete
            information about their Experience, including the date, time,
            location, and description.
          </p>
          <p>
            9.2. Guests are responsible for reviewing and confirming the
            details of the Experience before booking.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          10. Issues and Complaints
        </h4>
        <>
          <p>
            10.1. Users are encouraged to communicate directly with each other
            to resolve any issues or complaints that may arise during or
            after an Experience.
          </p>
          <p>
            10.2. If a User is unable to resolve an issue or complaint with the
            other party, they may contact Wareeg for assistance.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          11. Prohibited Conduct
        </h4>
        <>
          11.1. Users may not use the Platform to engage in any prohibited
          conduct, including but not limited to:
          <>
            <p className="ps-2"> 11.1.1. Harassment or discrimination of any kind</p>
            <p className="ps-2">11.1.2. Fraudulent or unlawful activity</p>
            <p className="ps-2">11.1.3. Posting false or misleading information</p>
            <p className="ps-2">11.1.4. Violating the intellectual property rights of others</p>
          </>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          12. Reporting Violations
        </h4>
        <>
          <p>
            12.1. Users are encouraged to report any violations of these Terms
            or any unlawful activity to Wareeg.
          </p>
          <p>
            12.2. We will investigate all reports of violations and take
            appropriate action.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          13. Indemnity
        </h4>
        <>
          <p>
            13.1. Users agree to indemnify and hold Wareeg, its affiliates,
            officers, agents, employees, and partners harmless from and
            against any and all claims, liabilities, damages, losses, and
            expenses, including reasonable attorneys'fees and costs,
            arising out of or in any way connected with:
          </p>
          <>
            <p className="ps-2">13.1.1. Users’ use of the Platform;</p>
            <p className="ps-2">13.1.2. Users’ violation of these Terms;</p>
            <p className="ps-2">13.1.3. Users’ violation of any rights of another party;</p>
            <p className="ps-2">13.1.4. Users’ violation of any law or regulation;</p>
            <p className="ps-2">
              13.1.5. Users’ interaction with any other User or third party,
              including but not limited to Experiencers (Guests) and
              Locals (Hosts);
            </p>
            <p className="ps-2">
              13.1.6. Any content or information posted or transmitted by Users
              on or through the Platform;
            </p>
            <p className="ps-2">
              13.1.7. Any dispute or issue that arises between Users or with a
              third party, including but not limited to any personal
              injury, death, or property damages that occurs during an
              Experience;
            </p>
            <p className="ps-2">
              13.1.8. Any claims made by a third party that the content or
              information posted or transmitted by Users on or through
              the Platform infringes on their intellectual property
              rights;
            </p>
            <p className="ps-2">
              13.1.9. Any claim that Wareeg is responsible for any taxes,
              duties, or fees related to any transaction or payment made
              through the Platform; or
            </p>
            <p className="ps-2">
              13.1.10. Any claim that Wareeg has failed to comply with any
              applicable law or regulation.
            </p>
          </>
          <p>
            13.2. This indemnification provision shall survive the termination
            of these Terms and Users'use of the Platform. Users agree to
            cooperate fully with Wareeg in the defense of any such claim.
            Wareeg reserves the right, at its own expense, to assume the
            exclusive defense and control of any matter subject to
            indemnification by Users.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          14. Limitation of Liability
        </h4>
        <>
          <p>
            14.1. By using the Wareeg platform, Users acknowledge and agree that
            Wareeg is not liable for any direct, indirect, incidental,
            special, consequential, or punitive damages arising out of or
            relating to these Terms or the use of the Platform. This
            includes, but is not limited to, any damages arising from
            personal injury, property damage, lost profits, loss of data,
            or business interruption.
          </p>
          <p>
            14.2. Users agree that they assume all risk associated with using
            the Platform and booking or participating in an Experience.
            Wareeg is not responsible for the quality, safety, or legality
            of any Experience offered by Hosts. Users acknowledge and
            agree that any bookings and participation in Experiences are
            done at their own risk.
          </p>
          <p>
            14.3. In no event shall Wareeg be liable for any damages, whether in
            contract, tort, strict liability, or otherwise, arising from
            or in connection with the use of the Platform or any
            Experience, even if Wareeg has been advised of the possibility
            of such damages.
          </p>
          <p>
            14.4. Users agree to indemnify, defend, and hold Wareeg and its
            affiliates, officers, agents, and employees harmless from any
            claims,damages, or losses arising from their use of the
            Platform or participation in any Experience.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          15. Termination
        </h4>
        <>
          <p>
            15.1. We may terminate a User's account at any time for any reason,
            including but not limited to, a violation of these Terms and
            Conditions or a violation of applicable laws.
          </p>
          <p>
            15.2. In the event that a Local's account is terminated, any
            existing bookings will be cancelled and any payments will be
            refunded to the Experiencer per our cancellation policy.
          </p>
          <p>
            15.3. Hosts acknowledge that they may be rejected from hosting an
            experience at any time, regardless of whether a booking has
            already been made, and acknowledge that this may disrupt any
            existing bookings. Wareeg will not guarantee the availability
            of a replacement host or the continuity of the experience.
          </p>
          <p>
            15.4. Upon termination, Users will no longer have access to the
            Platform.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          16. Legal Mandates
        </h4>
        <>
          <p>
            16.1. We may be required to cooperate with law enforcement or other
            jurisdictions in investigating unlawful activity or violations
            of these Terms.
          </p>
          <p>
            16.2. We reserve the right to disclose any information necessary to
            comply with legal mandates or to protect the safety and
            security of our Users.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          17. Copyright Infringement
        </h4>
        <>
          <p>
            17.1. We respect the intellectual property rights of others and
            expect our Users to do the same.
          </p>
          <p>
            17.2. We will investigate all reports of copyright infringement and
            take appropriate action.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          18. Intellectual Property
        </h4>
        <>
          <p>
            18.1. Users retain ownership of their own intellectual property
            rights.
          </p>
          <p>
            18.2. Users grant us a non-exclusive, royalty-free, transferable,
            sub-licensable, worldwide license to use any content they
            upload to the Platform.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          19. Arbitration and Class Action Waiver
        </h4>
        <>
          <p>
            19.1. Any disputes arising out of or relating to these Terms will be
            resolved through binding arbitration.
          </p>
          <p>
            19.2. Users waive the right to participate in any class action
            lawsuit or class-wide arbitration.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          20. Modification
        </h4>
        <>
          <p> 20.1. We reserve the right to modify these Terms at any time.</p>
          <p>
            20.2. Users will be notified of any changes to these Terms via the
            Platform or by email.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          21. Force Majeure
        </h4>
        <>
          <p>
            21.1. If either party is prevented from performing its obligations
            under these Terms and Conditions due to circumstances beyond
            its reasonable control, such as acts of God, natural
            disasters, war, terrorism, riots, embargoes, acts of civil or
            military authorities, fire, floods, accidents, pandemics,
            epidemics or disease, strikes, shortages of transportation
            facilities, fuel, energy, labor, or materials, Wareeg shall
            not be liable for any resulting delay or failure. In such a
            situation, the affected party will promptly inform the other
            party and use reasonable commercial efforts to resume
            performance as soon as it is reasonably practicable.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          22. General Provisions
        </h4>
        <>
          <p>
            22.1. These Terms constitute the entire agreement between Users and
            Wareeg.
          </p>
          <p>
            22.2. These Terms shall be governed by and construed in accordance
            with the laws of the State of Delaware where Wareeg
            Corporation is based.
          </p>
          <p>
            22.3. sers agree to resolve any disputes arising out of or relating
            to these Terms.
          </p>
        </>
        <h4 className="fs-5 mb-3" style={{ color: "#4189dd" }}>
          23. Miscellaneous
        </h4>
        <>
          <p>
            23.1. These Terms do not create any agency, partnership, or joint
            venture relationship between Users and Wareeg.
          </p>
          <p>
            23.2. If any provision of these Terms is found to be invalid or
            unenforceable, the remaining provisions will remain in effect.
          </p>
          <p>23.3. These Terms are binding.</p>
        </>
      </div>
    </SupportPageLayout>
  )
}

export default Terms
import React from "react";
import { Col, Row } from "react-bootstrap";
import Logo from "./Logo";
import styles from "./Footer.module.css";
import SocialIcons from "./SocialIcons";
import FooterLink from "./FooterLink";
import CommonLayout from "./CommonLayout";
import CopyRight from "./CopyRight";

const Footer = () => {
  return (
    <footer className={`${styles.footer} py-5`}>
      <CommonLayout>
        <Row>
          <Col sm="12" lg="5" className="mb-5">
            <div className="mb-4 mb-lg-5">
              <Logo />
            </div>
            <SocialIcons color="rgba(255,255,255)" />
          </Col>
          <Col sm="12" lg="7">
            <h6 className="fs-5 fw-bold text-white mb-3 mb-xl-4">Support</h6>
            <Row>
              <Col sm="12" lg="6" className="mb-3 mb-lg-0">
                <div className="vstack gap-3">
                  <FooterLink text="Help Center" href="https://helpcenter.wareeg.com/" />
                  <FooterLink text="Community Guidelines" href="/communityguidelines" />
                  <FooterLink text="Cancellation Policy" href="/cancellationpolicy" />
                </div>
              </Col>
              <Col sm="12" lg="6">
                <div className="vstack gap-3">
                  <FooterLink text="Legal" href="/legal" />
                  <FooterLink text="Contact Us" href="/contactus" />
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm="12">
            <div className="pt-4 pb-3">
              <hr style={{ opacity: "0.5", borderTopColor: "#4189dd" }} />
            </div>
          </Col>
          <CopyRight />
        </Row>
      </CommonLayout>
    </footer>
  );
};

export default Footer;
